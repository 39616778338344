import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { TimesIcon } from 'icons/TimesIcon';
import { CheckIcon } from 'icons/internal/CheckIcon';
import { HeartIcon } from 'icons/internal/HeartIcon';
import { Avatar } from 'components/Avatar';
import { IconButton } from 'components/Button';
import { Flex } from 'components/Flex';
import { IconText } from 'components/IconText';
import { Looper } from 'components/Looper';
import { Rhythm } from 'components/Rhythm';
import { Typography } from 'components/Typography';
import { ColorLooper } from 'docs/helpers/ColorLooper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { ColoredTag, StyledTag, Tag, TagGroup } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Tag" title="Tag" mdxType="PageTitle" />
    <h2 {...{
      "id": "pill-tags"
    }}>{`Pill tags`}</h2>
    <p><em parentName="p">{`Note that the links will cause the page to reload so the state will appear unchanged.`}</em></p>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Looper\n    list={[\'2xsmall\', \'xsmall\', \'small\', \'medium\', \'large\']}\n    render={size => (\n      <React.Fragment>\n        <Rhythm m={2}>\n          <Tag size={size} weight=\"shaded\">\n            Read only tag\n          </Tag>\n          <Tag\n            actionable\n            onClick={() => console.log(\'button tag\')}\n            size={size}\n            weight=\"shaded\"\n          >\n            Button tag\n          </Tag>\n          <Tag actionable as=\"a\" href=\"#tag\" size={size} weight=\"shaded\">\n            Link tag\n          </Tag>\n          <Tag actionable size={size} weight=\"outlined\">\n            Outlined tag\n          </Tag>\n          <Tag actionable size={size} weight=\"solid\">\n            Solid tag\n          </Tag>\n        </Rhythm>\n        <br />\n      </React.Fragment>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      CheckIcon,
      HeartIcon,
      Avatar,
      IconButton,
      Flex,
      IconText,
      Looper,
      Rhythm,
      Typography,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      ColoredTag,
      StyledTag,
      Tag,
      TagGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={['2xsmall', 'xsmall', 'small', 'medium', 'large']} render={size => <React.Fragment>
          <Rhythm m={2} mdxType="Rhythm">
            <Tag size={size} weight="shaded" mdxType="Tag">
              Read only tag
            </Tag>
            <Tag actionable onClick={() => console.log('button tag')} size={size} weight="shaded" mdxType="Tag">
              Button tag
            </Tag>
            <Tag actionable as="a" href="#tag" size={size} weight="shaded" mdxType="Tag">
              Link tag
            </Tag>
            <Tag actionable size={size} weight="outlined" mdxType="Tag">
              Outlined tag
            </Tag>
            <Tag actionable size={size} weight="solid" mdxType="Tag">
              Solid tag
            </Tag>
          </Rhythm>
          <br />
        </React.Fragment>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "brick-tags"
    }}>{`Brick tags`}</h2>
    <p><em parentName="p">{`Note that the links will cause the page to reload so the state will appear unchanged.`}</em></p>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Looper\n    list={[\'2xsmall\', \'xsmall\', \'small\', \'medium\', \'large\']}\n    render={size => (\n      <React.Fragment>\n        <Rhythm m={2}>\n          <Tag shape=\"brick\" size={size}>\n            Read only\n          </Tag>\n          <Tag\n            actionable\n            onClick={() => console.log(\'button tag\')}\n            shape=\"brick\"\n            size={size}\n          >\n            Button tag\n          </Tag>\n          <Tag actionable as=\"a\" href=\"#tag\" shape=\"brick\" size={size}>\n            Link tag\n          </Tag>\n          <Tag actionable shape=\"brick\" size={size} weight=\"outlined\">\n            Outlined tag\n          </Tag>\n          <Tag actionable shape=\"brick\" size={size} weight=\"solid\">\n            Solid tag\n          </Tag>\n        </Rhythm>\n        <br />\n      </React.Fragment>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      CheckIcon,
      HeartIcon,
      Avatar,
      IconButton,
      Flex,
      IconText,
      Looper,
      Rhythm,
      Typography,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      ColoredTag,
      StyledTag,
      Tag,
      TagGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={['2xsmall', 'xsmall', 'small', 'medium', 'large']} render={size => <React.Fragment>
          <Rhythm m={2} mdxType="Rhythm">
            <Tag shape="brick" size={size} mdxType="Tag">
              Read only
            </Tag>
            <Tag actionable onClick={() => console.log('button tag')} shape="brick" size={size} mdxType="Tag">
              Button tag
            </Tag>
            <Tag actionable as="a" href="#tag" shape="brick" size={size} mdxType="Tag">
              Link tag
            </Tag>
            <Tag actionable shape="brick" size={size} weight="outlined" mdxType="Tag">
              Outlined tag
            </Tag>
            <Tag actionable shape="brick" size={size} weight="solid" mdxType="Tag">
              Solid tag
            </Tag>
          </Rhythm>
          <br />
        </React.Fragment>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h3 {...{
      "id": "contrast-tag"
    }}>{`Contrast tag`}</h3>
    <p><em parentName="p">{`Note that the links will cause the page to reload so the state will appear unchanged.`}</em></p>
    <Playground __position={3} __code={'<ThemeWrapper contrast>\n  <Rhythm m={2}>\n    <Tag contrast>Read only tag</Tag>\n    <Tag actionable contrast onClick={() => console.log(\'button tag\')}>\n      Button tag\n    </Tag>\n    <Tag actionable contrast as=\"a\" href=\"#tag\">\n      Link tag\n    </Tag>\n    <Tag actionable contrast weight=\"outlined\">\n      Outlined tag\n    </Tag>\n    <Tag actionable contrast weight=\"solid\">\n      Solid tag\n    </Tag>\n  </Rhythm>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      CheckIcon,
      HeartIcon,
      Avatar,
      IconButton,
      Flex,
      IconText,
      Looper,
      Rhythm,
      Typography,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      ColoredTag,
      StyledTag,
      Tag,
      TagGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <Rhythm m={2} mdxType="Rhythm">
      <Tag contrast mdxType="Tag">Read only tag</Tag>
      <Tag actionable contrast onClick={() => console.log('button tag')} mdxType="Tag">
        Button tag
      </Tag>
      <Tag actionable contrast as="a" href="#tag" mdxType="Tag">
        Link tag
      </Tag>
      <Tag actionable contrast weight="outlined" mdxType="Tag">
        Outlined tag
      </Tag>
      <Tag actionable contrast weight="solid" mdxType="Tag">
        Solid tag
      </Tag>
    </Rhythm>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "colored-tags"
    }}>{`Colored tags`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <Flex drection=\"row\">\n      <Looper\n        list={[undefined, \'brick\']}\n        render={shape => (\n          <Flex direction=\"column\" key={`shape-${shape || \'default\'}`}>\n            <ColorLooper\n              group=\"primary\"\n              render={({ id }) => (\n                <Rhythm key={`${shape || \'default\'}-${id}`} mb={1} mr={4}>\n                  <TagGroup>\n                    <ColoredTag\n                      actionable\n                      colorId={id}\n                      shape={shape}\n                      weight=\"outlined\"\n                    >{`Color tag ${id}`}</ColoredTag>\n                    <ColoredTag\n                      actionable\n                      colorId={id}\n                      shape={shape}\n                      weight=\"shaded\"\n                    >{`Color tag ${id}`}</ColoredTag>\n                    <ColoredTag\n                      actionable\n                      colorId={id}\n                      shape={shape}\n                      weight=\"solid\"\n                    >{`Color tag ${id}`}</ColoredTag>\n                  </TagGroup>\n                </Rhythm>\n              )}\n              themeId={themeId}\n            />\n          </Flex>\n        )}\n      />\n    </Flex>\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      CheckIcon,
      HeartIcon,
      Avatar,
      IconButton,
      Flex,
      IconText,
      Looper,
      Rhythm,
      Typography,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      ColoredTag,
      StyledTag,
      Tag,
      TagGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <Flex drection="row" mdxType="Flex">
        <Looper list={[undefined, 'brick']} render={shape => <Flex direction="column" key={`shape-${shape || 'default'}`} mdxType="Flex">
              <ColorLooper group="primary" render={({
              id
            }) => <Rhythm key={`${shape || 'default'}-${id}`} mb={1} mr={4} mdxType="Rhythm">
                    <TagGroup mdxType="TagGroup">
                      <ColoredTag actionable colorId={id} shape={shape} weight="outlined" mdxType="ColoredTag">{`Color tag ${id}`}</ColoredTag>
                      <ColoredTag actionable colorId={id} shape={shape} weight="shaded" mdxType="ColoredTag">{`Color tag ${id}`}</ColoredTag>
                      <ColoredTag actionable colorId={id} shape={shape} weight="solid" mdxType="ColoredTag">{`Color tag ${id}`}</ColoredTag>
                    </TagGroup>
                  </Rhythm>} themeId={themeId} mdxType="ColorLooper" />
            </Flex>} mdxType="Looper" />
      </Flex>}
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "custom-tag-labels"
    }}>{`Custom tag labels`}</h3>
    <Playground __position={5} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <TagGroup>\n      <Tag key=\"remove\">\n        <IconText\n          reverse\n          icon={\n            <IconButton\n              aria-label=\"Remove tag\"\n              color=\"neutral\"\n              onClick={() => console.log(\'remove tag\')}\n              themeId={themeId}\n            >\n              <TimesIcon scale=\"xsmall\" />\n            </IconButton>\n          }\n          text={\n            <Rhythm mr={2}>\n              <span>Hello world</span>\n            </Rhythm>\n          }\n        />\n      </Tag>\n      <Tag\n        actionable\n        key=\"hello\"\n        onClick={() => console.log(\'tag\')}\n        shape=\"brick\"\n      >\n        <IconText\n          icon={<HeartIcon scale=\"small\" />}\n          text={\n            <Rhythm ml={2}>\n              <span>Hello world</span>\n            </Rhythm>\n          }\n        />\n      </Tag>\n    </TagGroup>\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      CheckIcon,
      HeartIcon,
      Avatar,
      IconButton,
      Flex,
      IconText,
      Looper,
      Rhythm,
      Typography,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      ColoredTag,
      StyledTag,
      Tag,
      TagGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <TagGroup mdxType="TagGroup">
        <Tag key="remove" mdxType="Tag">
          <IconText reverse icon={<IconButton aria-label="Remove tag" color="neutral" onClick={() => console.log('remove tag')} themeId={themeId} mdxType="IconButton">
                <TimesIcon scale="xsmall" mdxType="TimesIcon" />
              </IconButton>} text={<Rhythm mr={2} mdxType="Rhythm">
                <span>Hello world</span>
              </Rhythm>} mdxType="IconText" />
        </Tag>
        <Tag actionable key="hello" onClick={() => console.log('tag')} shape="brick" mdxType="Tag">
          <IconText icon={<HeartIcon scale="small" mdxType="HeartIcon" />} text={<Rhythm ml={2} mdxType="Rhythm">
                <span>Hello world</span>
              </Rhythm>} mdxType="IconText" />
        </Tag>
      </TagGroup>}
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "chip-tag"
    }}>{`Chip tag`}</h3>
    <p><em parentName="p">{`This is an example of how to make a custom chip from the Tag component. There is also a pre-defined `}<a parentName="em" {...{
          "href": "./Chip"
        }}>{`Chip`}</a>{` component.`}</em></p>
    <Playground __position={6} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <TagGroup>\n      <Tag flush key=\"close\" size=\"medium\">\n        <Flex\n          alignItems=\"center\"\n          direction=\"row\"\n          justifyContent=\"space-between\"\n        >\n          <Rhythm m={1} mr={2}>\n            <Avatar color=\"primary\" initials=\"P\" size=\"xsmall\" />\n          </Rhythm>\n          <Typography weights={[\'line-height-smash\']}>Hello world</Typography>\n          <Rhythm ml={4} p={1} style={{ marginRight: 5 }}>\n            <IconButton\n              noHeight\n              aria-label=\"Remove tag\"\n              color=\"neutral\"\n              onClick={() => console.log(\'remove tag\')}\n              shape=\"circle\"\n              themeId={themeId}\n            >\n              <TimesIcon scale=\"xsmall\" />\n            </IconButton>\n          </Rhythm>\n        </Flex>\n      </Tag>\n      <Tag flush key=\"check\" size=\"medium\">\n        <Flex\n          alignItems=\"center\"\n          direction=\"row\"\n          justifyContent=\"space-between\"\n        >\n          <Rhythm m={1} mr={2}>\n            <Avatar color=\"primary\" initials=\"P\" size=\"xsmall\" />\n          </Rhythm>\n          <Typography weights={[\'line-height-smash\']}>Hello world</Typography>\n          <Rhythm ml={4} mr={3}>\n            <CheckIcon scale=\"small\" />\n          </Rhythm>\n        </Flex>\n      </Tag>\n    </TagGroup>\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      CheckIcon,
      HeartIcon,
      Avatar,
      IconButton,
      Flex,
      IconText,
      Looper,
      Rhythm,
      Typography,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      ColoredTag,
      StyledTag,
      Tag,
      TagGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <TagGroup mdxType="TagGroup">
        <Tag flush key="close" size="medium" mdxType="Tag">
          <Flex alignItems="center" direction="row" justifyContent="space-between" mdxType="Flex">
            <Rhythm m={1} mr={2} mdxType="Rhythm">
              <Avatar color="primary" initials="P" size="xsmall" mdxType="Avatar" />
            </Rhythm>
            <Typography weights={['line-height-smash']} mdxType="Typography">Hello world</Typography>
            <Rhythm ml={4} p={1} style={{
                marginRight: 5
              }} mdxType="Rhythm">
              <IconButton noHeight aria-label="Remove tag" color="neutral" onClick={() => console.log('remove tag')} shape="circle" themeId={themeId} mdxType="IconButton">
                <TimesIcon scale="xsmall" mdxType="TimesIcon" />
              </IconButton>
            </Rhythm>
          </Flex>
        </Tag>
        <Tag flush key="check" size="medium" mdxType="Tag">
          <Flex alignItems="center" direction="row" justifyContent="space-between" mdxType="Flex">
            <Rhythm m={1} mr={2} mdxType="Rhythm">
              <Avatar color="primary" initials="P" size="xsmall" mdxType="Avatar" />
            </Rhythm>
            <Typography weights={['line-height-smash']} mdxType="Typography">Hello world</Typography>
            <Rhythm ml={4} mr={3} mdxType="Rhythm">
              <CheckIcon scale="small" mdxType="CheckIcon" />
            </Rhythm>
          </Flex>
        </Tag>
      </TagGroup>}
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "styled-tag"
    }}>{`Styled tag`}</h3>
    <Playground __position={7} __code={'() => {\n  const styles = {\n    primaryColor: \'#556270\',\n    inverseColor: \'#fff\',\n    hoveredPrimaryColor: \'#454f58\',\n    activePrimaryColor: \'#798796\',\n  }\n  return (\n    <Rhythm m={2}>\n      <StyledTag actionable {...styles}>\n        Shaded tag\n      </StyledTag>\n      <StyledTag actionable weight=\"outlined\" {...styles}>\n        Outlined tag\n      </StyledTag>\n      <StyledTag actionable weight=\"solid\" {...styles}>\n        Solid tag\n      </StyledTag>\n    </Rhythm>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      TimesIcon,
      CheckIcon,
      HeartIcon,
      Avatar,
      IconButton,
      Flex,
      IconText,
      Looper,
      Rhythm,
      Typography,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      ColoredTag,
      StyledTag,
      Tag,
      TagGroup,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const styles = {
          primaryColor: '#556270',
          inverseColor: '#fff',
          hoveredPrimaryColor: '#454f58',
          activePrimaryColor: '#798796'
        };
        return <Rhythm m={2} mdxType="Rhythm">
        <StyledTag actionable {...styles} mdxType="StyledTag">
          Shaded tag
        </StyledTag>
        <StyledTag actionable weight="outlined" {...styles} mdxType="StyledTag">
          Outlined tag
        </StyledTag>
        <StyledTag actionable weight="solid" {...styles} mdxType="StyledTag">
          Solid tag
        </StyledTag>
      </Rhythm>;
      }}
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "tag"
    }}>{`Tag`}</h3>
    <Props of={Tag} mdxType="Props" />
    <h3 {...{
      "id": "coloredtag"
    }}>{`ColoredTag`}</h3>
    <Props of={ColoredTag} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      